<template>
  <footer>
    <div class="footer-container">
      <div class="nav-wrapper">
        <div class="nav-wrapper-row">
          <div class="nav-block" style="margin-right: 20px">
            <span class="nav-block-title">关于我们</span>
            <div class="nav-block-content">
              <div class="nav-item">
                <a href="/about" rel="nofollow"><p>公司介绍</p></a>
              </div>
            </div>
          </div>
          <div class="nav-block">
            <span class="nav-block-title">版权登记</span>
            <div class="nav-block-content">
              <div class="nav-item">
                <a href="/software" rel="nofollow"
                  ><p>软件著作权</p></a
                >
                <hr />
                <a href="/product" rel="nofollow"
                  ><p>作品著作权</p></a
                >
              </div>
            </div>
          </div>

          <div class="nav-block">
            <span class="nav-block-title" style="width: 85px"
              >电子版权认证</span
            >
            <div class="nav-block-content">
              <div class="nav-item">
                <a href="/certificate" rel="nofollow" 
                  ><p>服务介绍</p></a>
                <hr />
                <!-- <a href="" rel="nofollow" style="pointer-events: none"
                  ><p>证书介绍</p></a
                >
                <hr /> -->
                <a href="/certificate" rel="nofollow" 
                  ><p>办理流程</p></a
                >
                <hr />
                <a href="/certificate" rel="nofollow" 
                  ><p>办理材料</p></a
                >
              </div>
            </div>
          </div>

          <div class="nav-block">
            <span class="nav-block-title">联系我们</span>
            <div class="nav-block-content">
              <div class="nav-item">
                <p>7×24小时客服</p>
              </div>
            </div>
          </div>

          <div class="nav-block" style="margin-left: -30px">
            <div style="padding-left: 35px">扫码加微信</div>
            <img src="@/assets/qrcode-01.jpg" class="wechaterweima" />
          </div>

          <div class="bottomiInput">
            <div class="bottomiInputBox">
              <div class="bottomiInput_right">
                <p style="font-size: 14px">
                  请留下您的联系信息，我们会尽快安排专属管家为您提供定制化服务
                </p>
                <form @submit.prevent="handleSubmit">
                  <div>
                    <label for="name">姓名</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      v-model="name"
                      required
                      style="width: 145px; margin-right: 25px"
                    />
                    <label for="tel">电话</label>
                    <input
                      type="text"
                      id="tel"
                      name="tel"
                      v-model="tel"
                      required
                      style="width: 207px"
                    />
                  </div>
                  <div>
                    <label for="note">留言</label>
                    <input
                      type="text"
                      id="note"
                      name="note"
                      required
                      style="width: 300px; margin-right: 10px"
                      v-model="note"
                    />
                    <button  type="submit">立即联系管家</button>
                  </div>
                  <div>
                    <p>*信息保护中，请放心填写*</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        text-align: center;
        opacity: 0.6;
        font-size: 12px;
        padding-bottom: 30px;
      "
    >
      <div style="display: inline; margin-right: 60px">
        公司地址：北京市顺义区顺畅大道14号院1号楼6层
      </div>
      <div
        style="
          margin-top: 10px;
          border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
          padding-bottom: 30px;
          display: inline;
        "
      >
        版权所有：北京智链创新科技有限公司<span style="margin-left: 60px"
          >京ICP备2024074692号-1</span
        >
      </div>
    </div>
  </footer>
</template>
<script>
import qs from "qs";
export default {
  
  name: "footercommon",
  components: {},
  data() {
    return {
      name: "",
      tel: "",
      note: "",
    };
  },
  methods: {
    handleSubmit(){
            const url = "/api/AddleavingServlet";
      let str = {
       name:this.name,
       phone:this.tel,
       message:this.note,
      };
      let str1 = qs.stringify(str);
      // console.log("参数", str1);
      this.axios
        .post(url, str1)
        .then((res) => {
          this.$message({
              message: "留言成功！",
              type: "success",
            });
           
        })
        .catch((err) => {
          //console.log(err),
           // console.log(err.response.data),
            this.$message({
              message: "失败！请检查网络",
              type: "warning",
            });
        });       
    }
  },
};
</script>
<style lang="less">
.wechaterweima {
  width: 100px;
  height: 100px;
  margin-left: 20px;
  margin-top: 10px;
}
.qrcode {
  margin-left: 120px;
  &::after {
    display: block;
    content: "";
    clear: both;
  }
  img {
    width: 100px;
    height: 100px;
    margin-left: 20px;
    margin-top: 10px;
  }
}
footer {
  background: #111;
  color: #fff;
  font-size: 14px !important;
  overflow: hidden;
  padding-top: 36px !important;
  position: relative;
}

footer .footer-container {
  // border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  display: flex;
  justify-content: center;
  margin: 0 auto 30px;
  max-width: 1300px;
  padding-bottom: 30px;
  // background-color: #e08543;
}

footer a:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

footer .nav-wrapper {
  display: inline-block;
  max-width: 1200px;
  vertical-align: top;
}

footer .nav-wrapper .nav-wrapper-row {
  display: flex;
  justify-content: space-between;
  margin-top: -16px;
  margin-right: 150px;
}

footer .nav-wrapper .nav-wrapper-row:first-child {
  margin-top: 0;
}

footer .nav-wrapper .nav-wrapper-row a {
  color: #fff;
}

footer .nav-wrapper .nav-wrapper-row .nav-item {
  // max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

footer .nav-wrapper .nav-block {
  display: inline-block;
  margin-right: 60px;
  vertical-align: top;
  width: 116px;
  // background-color: #e08543;
}

footer .nav-wrapper .nav-block > .nav-block-title {
  color: #fff;
  display: block;
  // font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;
}

footer .nav-wrapper .nav-block .nav-item {
  color: #fff;
  cursor: pointer;
  // font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  margin-right: 12px;
  // width: 52px;
}

footer .nav-wrapper .nav-block .nav-item a {
  color: #fff;
  display: inline-block;
  // opacity: 0.6;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: opacity 0.2s;
}

footer .nav-wrapper .nav-block .nav-item a:hover {
  opacity: 1;
}

footer .nav-wrapper .nav-block .nav-item a .tip-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  height: 7px;
  line-height: 7px;
  padding: 3px;
  position: relative;
  text-align: center;
  transform: scale(0.7);
  width: 7px;
  z-index: 99;
}

footer .nav-wrapper .nav-block .nav-item a .tip-icon:hover .tip-content {
  opacity: 1;
  visibility: visible;
}

footer .nav-wrapper .nav-block .nav-item a .tip-icon .tip-content {
  background: rgba(51, 51, 51, 0.95);
  border-radius: 4px;
  bottom: 60px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: #fff;
  // font-family: PingFangSC-Regular;
  font-size: 14px;
  left: -16px;
  line-height: 24px;
  opacity: 0;
  padding: 16px;
  position: absolute;
  text-align: left;
  transform: scale(1.42);
  transition: all 0.3s;
  visibility: hidden;
  width: 528px;
}

footer .nav-wrapper .nav-block .nav-item a .tip-icon .tip-content:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2c2c2c;
  bottom: -6px;
  content: "";
  display: block;
  left: 88px;
  position: absolute;
}

footer .nav-wrapper .nav-block .nav-item .footer-button {
  align-items: center;
  background: hsla(0, 0%, 100%, 0.1);
  border-radius: 15px;
  color: #fff;
  display: inline-flex;
  opacity: 0.6;
  padding: 4px 12px;
  transition: opacity 0.2s;
}

footer .nav-wrapper .nav-block .nav-item .footer-button:hover {
  opacity: 1;
}

footer .nav-wrapper .nav-block .nav-item .footer-button.call:before {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/no_call_tel.4f15aab.svg)
    50% / cover;
  content: "";
  display: inline-block;
  height: 10.4px;
  margin-right: 3px;
  width: 10.4px;
}

footer .nav-wrapper .nav-block .nav-item:nth-child(2n + 2) {
  margin-right: 0;
}

footer .nav-wrapper .nav-block:first-child {
  width: 164px;
}

footer .nav-wrapper .nav-block:first-child .nav-item {
  margin-right: 12px;
  width: 76px;
}

footer .nav-wrapper .nav-block:first-child .nav-item:nth-child(2n + 2) {
  margin-right: 0;
  width: 76px;
}

footer .followus {
  margin-left: 44px;
}

footer .followus__title {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 16px;
}

footer .followus__icons {
  display: flex;
  flex-wrap: wrap;
  width: 82px;
}

footer .followus__icons__item {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px;
  opacity: 0.6;
  position: relative;
}

footer .followus__icons__item .followus__icons__item__hover {
  align-items: center;
  height: 102px;
  justify-content: flex-start;
  left: 64px;
  padding: 0;
  top: -21px;
  width: 82px;
}

footer .followus__icons__item .followus__icons__item__hover img {
  height: 66px;
  margin: 0;
  width: 66px;
}

footer
  .followus__icons__item
  .followus__icons__item__hover
  .followus__icons__item__hover__qrcode {
  padding: 8px 8px 0;
}

footer
  .followus__icons__item
  .followus__icons__item__hover
  .followus__icons__item__hover__text {
  box-sizing: border-box;
  color: #666;
  font-size: 12px;
  line-height: 20px;
  overflow: hidden;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

footer .followus__icons__item .followus__icons__item__hover:after {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  border-top: 6px solid transparent;
  left: -12px;
  top: 24px;
}

footer .followus__icons__item:hover {
  opacity: 1;
}

footer .followus__icons__item:hover .wechat {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/icon_wechat.d3d32f9.svg);
}

footer .followus__icons__item:hover .toutiao {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/icon_toutiao.24535a3.svg);
}

footer .followus__icons__item:hover .douyin {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/icon_douyin.780a6e9.svg);
}

footer .followus__icons__item:hover .ocengine {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjEiLz48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGQ9Im0yMi43OSAxNS42MDIgMS4zNSA0LjQ5NEgxMy41MzNsMS4zNDctNC40OTRoNy45MVoiIGZpbGw9IiM3OEU1REIiLz48cGF0aCBkPSJtMTUuOTU2IDIwLjk0NSA0LjU2NyAxLjA4LTUuMzA1IDkuMTg1TDEyIDI3Ljc5NmwzLjk1Ni02Ljg1WiIgZmlsbD0iIzM1NjhENCIvPjxwYXRoIGQ9Im0xNy4xNjYgMjkuNTM0IDMuMjE4LTMuNDE3IDUuMzA1IDkuMTg4LTQuNTcgMS4wOC0zLjk1My02Ljg1WiIgZmlsbD0iIzMyNUFCMyIvPjxwYXRoIGQ9Ik0zMy4xMTkgMzIuNzgzSDI1LjIxbC0xLjM1LTQuNDk0aDEwLjYwNyIgZmlsbD0iIzM1NjhENCIvPjxwYXRoIGQ9Im0zNiAyMC41OS0zLjk1NiA2Ljg1LTQuNTY3LTEuMDggNS4zMDItOS4xODQiIGZpbGw9IiMzQzhCRkYiLz48cGF0aCBkPSJtMzAuODM1IDE4Ljg1MS0zLjIxNyAzLjQxNy01LjMwNS05LjE4OEwyNi44OCAxMmwzLjk1NiA2Ljg1MVoiIGZpbGw9IiMwMEM3RDEiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMiAxMikiIGQ9Ik0wIDBoMjR2MjQuMzg2SDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+);
}

footer .followus__icons__item:hover .followus__icons__item__hover {
  opacity: 1;
  visibility: visible;
}

footer .followus__icons__item .wechat {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/icon_wechat_light.7c3427e.svg);
  height: 20px;
  width: 24px;
}

footer .followus__icons__item .toutiao {
  background: url(https://lf-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/icon_toutiao_light.c1da618.svg);
  height: 36px;
  width: 36px;
}

footer .followus__icons__item .douyin {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuNzMyIDguMjFDNC4zOTYgOC4wNDkgMi4wNzggOS4yNTQuNzc2IDExLjgyNy0xLjE3NiAxNS42ODUuNDM3IDIyIDYuOTUxIDIyYzYuNTEzIDAgNi43MDItNi4zNzcgNi43MDItNy4wNDV2LTcuNDdjMS4zOTMuODk0IDIuNTY4IDEuNDMyIDMuNTI0IDEuNjE0Ljk1Ni4xODIgMS41NjQuMjYzIDEuODIzLjI0MlY1LjYyNWMtLjg4NC0uMTA4LTEuNjQ5LS4zMTQtMi4yOTQtLjYxOC0uOTY4LS40NTUtMi44ODctMS43Mi0yLjg4Ny0zLjU3M1YwSDkuNzg3Yy0uMDEyIDkuMDc3LS4wMTIgMTQuMDYyIDAgMTQuOTU1LjAxOCAxLjM0LTEuMDA4IDMuMjE0LTMuMDg3IDMuMjE0LTIuMDggMC0zLjEwNi0xLjg3My0zLjEwNi0zLjA4IDAtLjc0LjI1MS0xLjgxMSAxLjI4Ny0yLjYwNS42MTUtLjQ3IDEuNDY3LS42NTggMi44NTEtLjY1OFY4LjIxWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
  height: 22px;
  width: 19px;
}

footer .followus__icons__item .ocengine {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiLz48ZyBjbGlwLXBhdGg9InVybCgjYSkiIGZpbGw9IiNmZmYiPjxwYXRoIGQ9Im0yMi43OSAxNS42MDIgMS4zNSA0LjQ5NEgxMy41MzNsMS4zNDctNC40OTRoNy45MVpNMTUuOTU2IDIwLjk0NWw0LjU2NyAxLjA4LTUuMzA1IDkuMTg1TDEyIDI3Ljc5NmwzLjk1Ni02Ljg1Wk0xNy4xNjYgMjkuNTM0bDMuMjE4LTMuNDE3IDUuMzA1IDkuMTg4LTQuNTcgMS4wOC0zLjk1My02Ljg1Wk0zMy4xMTkgMzIuNzgzSDI1LjIxbC0xLjM1LTQuNDk0aDEwLjYwN00zNiAyMC41OWwtMy45NTYgNi44NS00LjU2Ny0xLjA4IDUuMzAyLTkuMTg0TTMwLjgzNSAxOC44NTFsLTMuMjE3IDMuNDE3LTUuMzA1LTkuMTg4TDI2Ljg4IDEybDMuOTU2IDYuODUxWiIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImEiPjxwYXRoIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyIDEyKSIgZD0iTTAgMGgyNHYyNC4zODZIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
  height: 48px;
  width: 48px;
}

footer .followus__icons__item__hover {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: -40px;
  opacity: 0;
  padding: 12px;
  position: absolute;
  top: 61px;
  transition: all 0.2s;
  visibility: hidden;
  width: 124px;
  z-index: 9;
}

footer .followus__icons__item__hover:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  left: calc(50% - 6px);
  position: absolute;
  top: -5px;
}

footer .followus__icons__item__hover__qrcode {
  height: 100px;
  margin-bottom: 4px;
  width: 100px;
}

footer .followus__icons__item__hover__text {
  color: #333;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

footer .copy-right {
  background: #000;
  color: #fff;
  // font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 19px;
  opacity: 0.35;
  padding: 22px 0;
  text-align: center;
  width: 100%;
}

footer .copy-right a {
  color: #fff;
}

footer .copy-right .license {
  margin: 0 10px;
}

.nav-block-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.followus {
  margin-left: 0 !important;
}

.connect {
  margin-left: 91px;
  width: 195px !important;
}

.connect .nav-block {
  margin: 0 !important;
  width: 195px !important;
}

.connect .nav-item {
  width: 100% !important;
}

.bottomiInput {
  // width: 1200px;
  // text-align: center;
  // background-color: rgb(47, 0, 255);
  margin: auto;
}
.bottomiInputBox {
  width: 100%;
  margin: auto;
  // background-color: aqua;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-left: 1px solid #eee;
  padding-left: 60px;
}
.bottomiInput_left {
  width: 40%;
  // margin-right: 10%;
  line-height: 30px;
}
.bottomiInput_right {
  // background-color: aqua;
  // float: right;
  width: 500px;
  margin-top: -10px;
  line-height: 40px;
}
.bottomiInput_right label {
  font-size: 16px;
  margin-right: 7px;
}
input[type="text"] {
  border-radius: 3px;
  height: 24px;
}
.bottomiInput_right button {
  border-radius: 3px;
  background-color: #4fabdf;
  color: #fff;
  padding: 4.5px 20px;
}
input[type="text"]:focus {
  outline: none;
}
</style>
