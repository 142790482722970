<template>
  <div>
    <div class="header-bg">
      <div class="header-inner header-inner-bg"></div>
    </div>
    <header>
      <div class="container">
        <div>
          <a href="/" class="logo-wrapper nuxt-link-active"
            ><div>
              <!-- <img
                src="https://lf3-cdn-tos.bytescm.com/obj/static/cg_growth/oceanengine/client/img/logo.0711eb0.svg"
                title="巨量引擎logo"
                alt="巨量引擎logo"
              /> -->
              <img
                src="./assets/logo.png"
                title="企小虎logo"
                alt="企小虎logo"
                style="width: 100px;display: block;margin-top: -8px;"
              /></div
          ></a>
          <ul class="nav">
            <li>
              <div>
                <a href="/" class="first-nav first-nav-accent">首页</a>
                <ul class="nav-item-options"></ul>
              </div>
            </li>
            <li>
              <div>
                <span class="first-nav first-nav-accent">版权登记</span>
                <ul class="nav-item-options">
                  <li>
                    <a href="/software"> 软件著作权 </a>
                  </li>
                  <li>
                    <a href="/product"> 作品著作权 </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div>
                <a href="/certificate" class="first-nav first-nav-accent"
                  >电子版权认证</a
                >
                <ul class="nav-item-options"></ul>
              </div>
            </li>
            <li>
              <div>
                <a href="/about" class="first-nav first-nav-accent">关于我们</a>
                <ul class="nav-item-options"></ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  name: "headercommon",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less">
.header-bg {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .header-inner-bg {
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
    background: #000;
    z-index: 998;
  }
  .header-inner {
    height: 64px;
    opacity: 0.8;
    transition: background 0.2s;
  }
}
header {
  background: transparent;
  font-size: 0;
  height: 64px;
  left: 0;
  min-width: 1264px;
  position: fixed;
  top: 0;
  transition: background 0.2s ease-in-out;
  width: 100%;
  z-index: 999;
  .container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 24px;
    white-space: nowrap;
    width: 100%;
    .logo-wrapper {
      display: inline-block;
      height: 29px;
      margin-right: 48px;
      width: 104px;
      img {
        transform: translateY(8px);
      }
    }
    .nav {
      display: inline-block;
      height: 100%;
      & > li {
        align-items: center;
        display: inline-flex;
        // font-family: PingFangSC-Medium;
        font-size: 14px;
        height: 100%;
        position: relative;
        &:hover {
          opacity: 1;
          .first-nav {
            cursor: pointer;
            opacity: 1;
          }
          .nav-item-options {
            opacity: 1;
            top: 64px;
            transition: all 0.2s, opacity 0.2s 0.1s;
            visibility: visible;
          }
        }
        & > div {
          line-height: 64px;
          padding: 0 20px;
        }
        .first-nav {
          color: #fff;
          &::after {
            background: #fff;
            border-radius: 2px;
            bottom: 14px;
            content: "";
            height: 4px;
            left: 50%;
            opacity: 0;
            position: absolute;
            transform: translateX(-50%);
            transition: opacity 0.2s;
            visibility: hidden;
            width: 16px;
            z-index: 100;
          }
        }
        span {
          color: #fff;
        }
        .nav-item-options {
          -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
          background: rgba(0, 0, 0, 0.74);
          border-radius: 0 0 4px 4px;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: all 0.2s, opacity 0.1s;
          visibility: hidden;
          width: 150px;
          z-index: -1;
          li {
            line-height: 22px;
            padding: 12px 24px;
            &:hover {
              background: rgba(0, 0, 0, 0.3);
              a {
                color: #fff;
              }
            }
            a {
              color: hsla(0, 0%, 100%, 0.6);
              opacity: 1;
              transition: color 0.2s;
            }
          }
        }
      }
    }
  }
}
</style>
