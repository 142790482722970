<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <headercommon></headercommon>
    <router-view />


    <!-- <div class="bigBox">
      <div class="one" @click="kefuDialog">
        <img src="./assets/hujiaoguanjia.png">
      </div>
      <div class="two">
        <img src="./assets/tel.png">
      </div>
      <div class="three">
        <img src="./assets/qywechat.png">
      </div>
    </div> -->

    <onlineCustomer></onlineCustomer>
    <!-- <div v-show="keDialog" class="dialog">
      <onlineCustomer></onlineCustomer>
    </div> -->



    <footercommon></footercommon>
  </div>
</template>
<script>
import headercommon from "./headercommon.vue";
import footercommon from "./footercommon.vue";
import onlineCustomer from './components/onlineCustomer.vue'
import './style.scss';
export default {
  components: { headercommon, footercommon ,onlineCustomer},
  data() {
    return {
      // keDialog:false
    };
  },
  methods: {
    // kefuDialog(){
    //   this.keDialog=true
    // }
  },
};
</script>
<style lang="less">

// .bigBox{
//   background-color: #4fabdf;
//   color: #fff;
//   position: fixed;
//   top: 500px;
//   right: 0;
//   z-index: 1000;
// }
// .dialog{
//   width: 600px;
//   // height: 500px;
//   position: fixed;
//   right: 0;
//   bottom: 10px;
//   z-index: 2000;
// }

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font-size: 100%;
  font-weight: 400;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
*,
:after,
:before {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
body {
  
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
a {
  -webkit-backface-visibility: hidden;
  color: #000;
  -webkit-text-decoration: none;
  text-decoration: none;
}
.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 32px;
  width: 1264px;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}

.easemobim-minimized {
  top: 50% !important
}
.em-widget-pop-bar {
  height: 50px;
  line-height: 50px;
}
</style>
