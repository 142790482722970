import Vue from "vue";
// 前提：安装模块后才能用 npm i axios vue-axios
import axios from "axios";
import VueAxios from "vue-axios";

// 设置axios的基础路径属性,
// 后续利用axios发送的请求地址，会自动拼接到此路径后面
axios.defaults.baseURL = '/',
    // use(两个参数)
    axios.interceptors.request.use(config => {
        // 在发送请求前做一些事
        const token = window.localStorage.getItem('token');
        //console.log('token', token)
        if (token) {
            // 已经登录成功，统一添加token
            config.headers.Authorization = token

        } else {
            // this.$router.push('/login')
        }
        return config
    }, err => {
        // 在请求错误时做的事儿

        return Promise.reject(err)
    })
Vue.use(VueAxios, axios)